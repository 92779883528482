<template>
  <div id="confirmEmail">
    <div class="welcomeMessageWrapper">
      <div class="mainContainerWrapper">
        <!--
        <div class="logoWrapper">
          <img :src="$store.getters.mainLogos.desktop" alt="Logo" />
        </div>
        -->
        <div class="mainContainer">
          <!-- <h1>{{this.$store.getters.emailConfirmed}}</h1> -->
          <!-- <button>change</button> -->
          <div class="titleWrapper">
            <div
              class="title"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.emailConfirmation.confirmYourEmail =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.emailConfirmation.confirmYourEmail
              }}
            </div>
          </div>

          <div class="mainTextWrapper">
            <h3 class="titleText">
              {{
                $t($i18n.locale).keys.emailConfirmation.weHaveSent
              }} <strong>{{email}}</strong>
              </h3>
              <div>

                <a class="emailChangeLink" href=""
                  @click.prevent="changeEmailModalShow = true"
                >
                  {{$t($i18n.locale +'.keys.membershipArea.myAccount.personalDetails.wrongEmail')}}
                </a>
              </div>
            <div
              class="mainText"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.emailConfirmation.toContinue =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.emailConfirmation.toContinue
              }}
            </div>
          </div>
          <div class="buttonWrapper">
            <button @click="reSendEmail" class="btnResend" v-if="!lockResend">
              {{$t($i18n.locale).keys.generic.resendEmailBtn}}
            </button>
            <strong v-else >{{$t($i18n.locale).keys.generic.emailSent}}</strong>
            <!-- <button @click="$store.dispatch('resendConfirmationEmail')" class="btnResend">Resend e-mail</button>-->
            <input
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.resendEmailBtn"
                type="text"
              />
          </div>
          <a class="emailReconfirmLink"
          href=""
          @click.prevent="reCheckEmail"
          >
            {{$t($i18n.locale).keys.generic.reCheckEmailLink}} >
          </a>
        </div>
      </div>
    </div>
    <b-modal id="changeEmailModal" v-model="changeEmailModalShow" hide-footer hide-header>
        <div class="exit-button" @click="changeEmailModalShow = false">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Close-cross-Grey.svg')" alt="Exit icon" />
        </div>
        <div class="container">
          <Spinner v-if="isLoading" />
            <div class="row">

            <div class="content col-12">
              <div class="detailWrapper">
                <!-- start -->
                <form @submit.prevent="validateEmail()">
                  <div class="detailsTextWrapper">
                    <h1 class="detailsText">
                      {{
                        $t($i18n.locale +'.keys.membershipArea.myAccount.personalDetails.changeEmail')
                      }}
                    </h1>
                  </div>
                  <div class="generalError error" v-if="generalError">
                    {{generalError}}
                  </div>
                  <div  class="detailsInputWrapper">
                    <input class="genericInput" readonly :value="email" />
                  </div>
                  <div class="detailsInputWrapper">
                    <p
                      v-if="newEmailError"
                      class="error"
                    >
                      {{newEmailError}}
                    </p>
                    <input
                      type="email"
                      class="genericInput"
                      :class="{
                        emptyError: newEmailError
                      }"
                      v-model="newEmail"
                      :placeholder="
                        $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                          .newEmailAddress
                      "
                    />
                  </div>
                  <div class="detailsInputWrapper">
                    <p
                      v-if="repeatEmailError"
                      class="error"
                    >
                      {{repeatEmailError}}
                    </p>

                    <input
                      type="email"
                      class="genericInput"
                      :class="{
                        emptyError: repeatedEmailEmptyError,
                        emptyError: repeatedEmailBadFormatError,
                        emptyError: notSameEmailsError,
                        emptyError: sameAsOldError,
                        emptyError: $store.getters.getEmail,
                      }"
                      v-model="repeatedEmail"
                      :placeholder="
                        $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                          .confirmEmail
                      "
                    />
                  </div>
                </form>
                <!-- end -->
              </div>
              <div class="bottom">
                <b-button @click="validateEmail()" variant="primary" class="my-3">
                  {{$t($i18n.locale+'.keys.membershipArea.myAccount.personalDetails.saveChnages') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ConfirmEmail",
  components: {},
  data() {
    return {
      email: "",
      repeatedEmail: "",
      newEmail: "",
      componentKeyEmail: 0,
      newNewEmail: "",
      newRepeatedEmail: "",
      lockResend: false,
      changeEmailModalShow: false,
      generalError: '',
      newEmailError:'',
      repeatEmailError:'',
      isLoading: false,
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    emailConfirmed: function() {
      return  JSON.parse(localStorage.getItem("emailConfirmed"));
    },
    newEmailEmptyError: function() { return this.$t(this.$i18n.locale + '.keys.membershipArea.myAccount.personalDetails.enterEmail');},
    repeatedEmailEmptyError: function() { return this.$t(this.$i18n.locale + '.keys.membershipArea.myAccount.personalDetails.enterEmail');},
    newEmailBadFormatError: function() { return this.$t(this.$i18n.locale + '.keys.membershipArea.myAccount.personalDetails.enterValidEmail');},
    repeatedEmailBadFormatError: function() { return this.$t(this.$i18n.locale + '.keys.membershipArea.myAccount.personalDetails.enterValidEmail');},
    notSameEmailsError: function() { return this.$t(this.$i18n.locale+'.keys.membershipArea.myAccount.personalDetails.repeatEmail');},
    sameAsOldError: function() { return this.$t(this.$i18n.locale+'.keys.membershipArea.myAccount.personalDetails.emailShouldMatch');},
    alreadyExistsError: function() { return this.$t(this.$i18n.locale+'.keys.membershipArea.myAccount.personalDetails.alreadyExist');},
  },
   mounted() {
          this.$store.dispatch("getEmailConfirmed");
          this.$store.dispatch("checkCompleted").then(res => {
            console.log('checkCompleted', res)
            if(res.confirmFirst) {
              //Trigger Registration Event
              console.log("Trigger Registration Event");
              var URL = process.env.VUE_APP_URL + '/api/personality/trigger/registration';
              //Set user device
              var device = '';
              if(this.$store.getters.isMobile) device = 'mobile';
              else device = 'desktop';

              axios.post(URL, {
                device: device
              }).then(() => {
                console.log('Sent Registration Event');
              });
            }
          });
          this.$store.dispatch("fetchClientSetttings").then(() => {
          this.email = this.$store.getters.getClientSettings.personal_details.mail;
          });

  },
  methods: {
    // test(){
    //   localStorage.setItem("emailConfirmed", JSON.stringify({"emailConfirmed":true, "email":this.emailConfirmed.email}));
    //    console.log(localStorage.getItem("emailConfirmed"))
    // }
    reSendEmail: function(){
      if(this.lockResend) return false;
      this.lockResend = true;
      this.$store.dispatch('resendConfirmationEmail');

    },
    reCheckEmail: function(){
      this.$store.dispatch("getEmailConfirmed").then((result) => {
        console.log('reCheckEmail', this.$store.getters.completed)
        if(result == true){
          if(!this.$store.getters.completed) {
            if (!this.$store.getters.hideFirstMessage) {
              this.$router.push("/welcome-message");
            } else {
              this.$router.push("/personality-test");
            }
          }else {
            this.$router.push('/upload-image'); //this.$router.push("/additional-question");
          }
        } 
      });
    },
    validateEmail() {
      // eslint-disable-next-line
      const regEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

      this.generalError = '';
      this.newEmailError = '';
      this.repeatEmailError = '';

      if (this.newEmail !== "") {
        if (this.newEmail.match(regEx)) {
          if (
            this.newEmail == this.repeatedEmail &&
            this.newEmail !== this.email
          ) {
            this.newNewEmail = this.newEmail;
          } else if (
            this.newEmail == this.repeatedEmail &&
            this.newEmail == this.email
          ) {
            this.generalError = this.sameAsOldError;
          } else {
            this.generalError = this.notSameEmailsError;
          }
        } else {
          this.newEmailError = this.newEmailBadFormatError;
        }
      } else {
        this.newEmailError = this.newEmailEmptyError;
      }
      if (this.repeatedEmail !== "") {
        if (this.repeatedEmail.match(regEx)) {
          if (
            this.newEmail == this.repeatedEmail &&
            this.repeatedEmail !== this.email
          ) {
            this.newRepeatedEmail = this.repeatedEmail;
          } else if (
            this.newEmail == this.repeatedEmail &&
            this.repeatedEmail == this.email
          ) {
            this.generalError = this.sameAsOldError;
          } else {
            this.generalError = this.notSameEmailsError;
          }
        } else {
          this.repeatEmailError = this.repeatedEmailBadFormatError;
        }
      } else {
        this.repeatEmailError = this.repeatedEmailEmptyError;
      }
      if (this.newRepeatedEmail !== "" && this.newNewEmail !== "") {
        let newEmail = {
          email: this.newNewEmail,
          confirmEmail: this.newRepeatedEmail,
        };
        this.generalError = '';
        this.newEmailError = '';
        this.repeatEmailError = '';
        this.isLoading = true;

        this.$store.dispatch("updateEmail", newEmail).then(res => {
          this.isLoading = false;

          console.log(res);
          if(res.errors){
            this.generalError = res.errors;
          }else{
            this.changeEmailModalShow = false;
            this.lockResend = false;
            this.email = this.newNewEmail;
            this.newNewEmail = this.newRepeatedEmail = this.newEmail = this.repeatedEmail = "";
            this.newRepeatedEmail = "";
          }
        });
       
      }
    }
  },
};
</script>
